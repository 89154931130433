//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name:'alike',
  props: {
    initData: {
      type: Object
    },
    menuAlike: {
      type: Object,
      default: () => {
        return {
          typeSelect: [],
          classSelect: []
        }
      }
    }
  },
  data(){
    return {
      isCheckAll: false,
      isClassCheckAll: false,
      typeSelect: [],
      classSelect: []
    }
  },
  mounted() {
    if(this.menuAlike.typeSelect.length > 0 && this.menuAlike.typeSelect.length < this.initData.approximate.menu.length){
      this.typeSelect = this.menuAlike.typeSelect
    } else {
      this.checkAll(true);
    }

    if(this.menuAlike.classSelect.length > 0 && this.menuAlike.classSelect.length < 45){
      this.classSelect = this.menuAlike.classSelect
    } else {
      this.classCheckAll(false);
    }
  },
  methods: {
    checkAll(value){
      let ids = [];
      this.initData.approximate.menu.map(item => {
        ids.push(item.id);
      })
      this.typeSelect = value ? ids : [];
      this.isCheckAll = this.typeSelect.length === this.initData.approximate.menu.length;
    },
    classCheckAll(value){
      let ids = [];
      this.initData.trademarkTypes.map(item => {
        ids.push(item.value);
      })
      this.classSelect = value ? ids : [];
      this.isClassCheckAll = this.classSelect.length === this.initData.trademarkTypes.length;
    },
    selectChange(type){
      switch (type){
        case 1: this.isCheckAll = this.typeSelect.length === this.initData.approximate.menu.length; break;
        case 2: this.isClassCheckAll = this.classSelect.length === this.initData.trademarkTypes.length; break;
      }
    },
    resetConfirm(type){
      switch (type){
        case 1: this.typeSelect = []; this.isCheckAll = false; break;
        case 2: this.classSelect = []; this.isClassCheckAll = false; break;
      }
    },
    filterConfirm(){
      let param = {
        typeSelect: this.typeSelect,
        classSelect: this.classSelect
      }
      this.$emit('filterConfirmAlike', param)
    }
  }
}
