/**
 * 特殊字符
 * */
export function validateCharacter(value) {
  //const reg = /[<>*&(){}【】（）《》？%.\/]+/g;
  const reg = /[<>*&{}【】\/]+/g;
  return reg.test(value);
}
/**
 * 特殊字符2（多加了英文的双引号）
 * */
export function validateCharacters(value) {
  //const reg = /[<>*&(){}【】（）《》？%.\/]+/g;
  const reg = /[<>*&{}""【】\/]+/g;
  return reg.test(value);
}

/**
 * 仅中文
 * */
export function validateText(value) {
  const reg = /^[\u4e00-\u9fa5]+$/;
  return reg.test(value);
}

/**
 * 数字，汉字，字母
 * */
export function validateName(value) {
  const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+$/;
  return reg.test(value);
}
/**
 * 数字，汉字，字母，（）()
 * */
export function validateNameKH(value) {
  const reg = /^[()（）A-Za-z0-9\u4e00-\u9fa5]+$/;
  return reg.test(value);
}
/**
 * 数字，汉字，字母，（）()和 中英逗号，,
 * */
export function validateNameKHD(value) {
  const reg = /^[()（），,A-Za-z0-9\u4e00-\u9fa5]+$/;
  return reg.test(value);
}

/**
 * 数字，汉字，字母，空格
 * */
export function validateNameSp(value) {
  const reg = /^[A-Za-z0-9\s\u4e00-\u9fa5]+$/;
  return reg.test(value);
}

/**
 * 手机号验证
 * */
export function isvalidPhone(str) {
  const reg = /^1[3-9]\d{9}$/;
  return reg.test(str)
}

/**
 * 金额验证8位数
 * @param value
 * @returns {boolean}
 */
export function validMoney(value) {
  const reg = /^([1-9][\d]{0,7}|0){1}(\.[\d]{1,2})?$/;
  return reg.test(value);
}
/**
 * 金额验证7位数
 * @param value
 * @returns {boolean}
 */
export function validMoney7(value) {
  const reg = /^([1-9][\d]{0,6}|0){1}(\.[\d]{1,2})?$/;
  return reg.test(value);
}
/**
 * 金额验证（可验证9位数）
 * @param value
 * @returns {boolean}
 */
export function validCost(value) {
  const reg = /^([1-9][\d]{0,8}|0){1}(\.[\d]{1,2})?$/;
  return reg.test(value);
}

/**
 * 金额验证（可验证10位数）
 * @param value
 * @returns {boolean}
 */
export function validMoney10(value) {
  const reg = /^([1-9][\d]{0,9}|0){1}(\.[\d]{1,2})?$/;
  return reg.test(value);
}

/**
 * 邮箱地址验证
 * @param value
 * @returns {boolean}
 */
export function validMail(value) {
  const reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
  return reg.test(value);
}

/**
 * 多个邮箱地址验证
 * @param value
 * @returns {boolean}
 */
export function validMailMore(value) {
  const reg = /^((([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6}\,))*(([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})))$/;
  return reg.test(value);
}

/**
 * 数字 英文 逗号 和 空格
 * @param value
 * @returns {boolean}
 */
export function validTrademarkNum(value) {
  const reg = /^[A-Za-z0-9,\s]+$/;
  return reg.test(value);
}

/**
 * 数字 英文 空格
 * @param value
 * @returns {boolean}
 */
export function validTrademarkNumOne(value) {
  const reg = /^[A-Za-z0-9\s]+$/;
  return reg.test(value);
}

/**
 * 只能有数字 字母 汉字 其中一种
 * @param value
 * @returns {boolean}
 */
export function onlyStr(str) {
  const reg = /^[A-Za-z0-9\s\u4e00-\u9fa5]+$/;
  if(!reg.test(str)){
    return false;
  }
  var a = /[0-9]/;
  var isNum = a.test(str);//true,说明有数字
  var a = /[a-z]/i;
  var isLetter = a.test(str);//true,说明有英文字母
  var a = new RegExp("[\\u4E00-\\u9FFF]+", "g");
  var isCn = a.test(str);//true,说明有汉字
  return isNum && isLetter || isNum && isCn || isLetter && isCn
}

/**
 * 非空判断
 * @param value
 * @returns {boolean}
 */
export function isEmpty(obj){
  var regu = "^[ ]+$";
  var re = new RegExp(regu);
  if(typeof(obj) == "undefined" || obj == null || obj == "" || re.test(obj)){
      return true;
  }else{
      return false;
  }
}
/**
 * 申请人-特殊符号判断
 * @param value
 * ~!@#$%^*+=[]{}\|";:“；。<>/?？+
 * @returns {boolean}
 */
export function validApplicant(value) {
  const reg = /^.*[~!@#\$%\^\*\+=\[\]\{\}\\\|\";:\“；。\<\>\/\?\？+].*$/;
  return reg.test(value);
}


/**
 * 数字 逗号(中英文)
 * @param value
 * @returns {boolean}
 */
 export function validTMClass(value) {
  const reg = /^[0-9,，]+$/;
  return reg.test(value);
}


/**
 * 仅数字
 * @param value
 * @returns {boolean}
 */
 export function validNumber(value) {
  const reg = /^[0-9]+$/;
  return reg.test(value);
}

/**
 * 空格和逗号转为 英文逗号隔开
 * @param str
 * @returns {string}
 */
export function spacesAndCommasToComma(str) {
  return str.replace(/[ ,，]/g, ',');
}


